.wrapper {
    background-color: aliceblue;
    width: 100%;
    height: 100%;
    box-shadow: 5px 3px 5px grey;
    -webkit-transition: box-shadow 0.2s ease-in;
    display: block;
    margin: 1px auto;
}

.wrapper:hover {
    box-shadow: 5px 2px 5px grey;
    -webkit-transition: box-shadow 0.2s ease-in;
}
